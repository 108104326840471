body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Poppins', sans-serif;
}

.g9 {
  color: #999;
}

.bebas {
  font-family: 'Bebas Neue', cursive;
}

.m0 {
  margin: 0;
}

.min__height {
  min-height: 90vh;
}

/* BORDER */

.br15 {
  border-radius: 15px;
}

/* COLORS */

.bg1 {
  background-color: #6E1476;
}

.bg2 {
  background-color: #fff;
}

.bgl1 {
  background: linear-gradient(to bottom, #000, #fff);
}

.bgl2 {
  background: linear-gradient(45deg, #000, #fff);
}

.c1 {
  color: #F57140;
}

.c2 {
  color: #FBD54E;
}

.white {
  color: white;
}

.g7 {
  color: #777;
}

/* FONTS */

.font10 {
  font-size: 10px;
}

.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}

.font18 {
  font-size: 18px;
}

.font20 {
  font-size: 20px;
}

.font22 {
  font-size: 22px;
}

.font24 {
  font-size: 24px;
}

.font26 {
  font-size: 26px;
}

.font28 {
  font-size: 28px;
}

.font30 {
  font-size: 30px;
}

.font32 {
  font-size: 32px;
}

.font34 {
  font-size: 34px;
}

.font36 {
  font-size: 36px;
}

.font38 {
  font-size: 38px;
}

.font40 {
  font-size: 40px;
}

.font42 {
  font-size: 42px;
}

.font44 {
  font-size: 44px;
}

.font46 {
  font-size: 46px;
}

.font48 {
  font-size: 48px;
}

.font64 {
  font-size: 64px;
}

/* WEIGHTS */

.wg2 {
  font-weight: 200;
}

.wg3 {
  font-weight: 300;
}

.wg4 {
  font-weight: 400;
}

.wg5 {
  font-weight: 500;
}

.wg6 {
  font-weight: 600;
}

.wg7 {
  font-weight: 700;
}

.wg8 {
  font-weight: 800;
}

.wg9 {
  font-weight: 900;
}