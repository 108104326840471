.bg1x {
    background-color:#F57140;
}

.bg2x {
    background: url("https://i.ibb.co/LtnfVh2/bgx2.png") no-repeat;
    background-position: top;
    background-size: cover;
}

.bg3x {
    background: url("https://i.ibb.co/mDhfzwQ/bgx3.png") no-repeat;
    background-position: center;
    background-size: cover;
}

.badge__time {
    border: #FFB28C solid 2px;
    border-radius: 14px;
}

.badge__time__text {
    margin: 0px;
}

.badge__time__text2 {
    margin-bottom: 10px;
}

.input__mod {
    height: 45px;
    border-radius: 14px;
    padding: 0px 35px;
}

.btn__cta {
    min-height: 45px;
    border-radius: 14px;
    font-weight: 700;
    color: white;
    background-color: #18BF5B;
    border: transparent;
}

.eem__card1 {
    background-color: white;
    min-height: 230px;
    border-radius: 25px;
}

@media(max-width:1200px) {
    .font64 {
        font-size: 34px;
    }
    .font20 {
        text-align: center !important;
        font-size: 16px;
    }
    .quemsoueu__text {
        text-align: center !important;
    }
    .quemsou__text {
        margin: 20px 0px 10px !important;
    }
}